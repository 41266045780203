.project {
    margin-top: 1%;
    margin-bottom: 1%;
    padding: 2%;
}

.project h1 {
}

.project p {
}

.mathbio-projects h1 {
    text-align: left;
}

.mathbio-projects {
    background: linear-gradient(to bottom, grey, transparent);
    border-left: 3px dotted #4c4091; 
    border-right: 3px dotted #4c4091;
    border-top: 2px solid #4c4091;
}

.intro {
    background: linear-gradient(to bottom, grey, transparent);
    border-left: 3px dotted #4c4091; 
    border-right: 3px dotted #4c4091;
}

.intro h1 {
    text-align: left;
}

.cloud-projects {
}

.gravwave-projects {
}

.gravwave-projects h1 {
    text-align: left;
}

.cloud-projects h1 {
    text-align: left;
}

.bioinformatics-projects {
    background: linear-gradient(to bottom, grey, transparent);
    border-left: 3px dotted #4c4091; 
    border-right: 3px dotted #4c4091;
    border-top: 2px solid #4c4091;
}

.bioinformatics-projects h1 {
  text-align: left;
}

.image-container {
  position: relative;
  width: 200px;
  height: 200px;
  margin: auto auto;
  padding: 10% 10%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.foreground {
  z-index: 1;
}

.background {
  z-index: 0; 
}
