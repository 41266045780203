.navbar-custom {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 1.5); /* Shadow under the top bar */
    border-bottom: 2px solid #1f1c1d; /* Top border with 4px thickness and red color */
}

.navbar-custom .nav-link {
  color: white;
}

.navbar-custom .nav-link:hover {
  color: #573e1b;
}

.navbar-custom .navbar-toggler {
  border-color: transparent;
}

.nav-link {
  text-align: center;
}

.navbar-custom .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  /* Change the stroke='rgba(255, 255, 255, 0.5)' value to your preferred color */
}
