.float-effect {
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px); /* Adjust amplitude as needed */
  }
  100% {
    transform: translateY(0);
  }
}

.spin-effect {
  display: inline-block;
  animation: spin var(--spin-speed) linear infinite;
  transform: rotate(var(--spin-offset));
}

@keyframes spin {
  from {
    transform: rotate(calc(var(--spin-offset) + 0deg));
  }
  to {
    transform: rotate(calc(var(--spin-offset) + 360deg));
  }
}
